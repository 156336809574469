import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { venueDetailsMapping } from '../misc/venueDetailsMapping';

import '../styles/TagsMatching.css';

const TagsMatching = () => {
  const navigate = useNavigate();
  const { user_id } = useParams();

  const options = [
    { value: 'Garden', label: 'Garden' },
    { value: 'Mountain', label: 'Mountain View' },
    { value: 'Ocean', label: 'Ocean View' },
    { value: 'Desert', label: 'Desert View' },
    { value: 'Winery', label: 'Winery' },
    { value: 'National Park', label: 'National Park' },
    { value: 'Ranch', label: 'Ranch' },
    { value: 'Golf Club', label: 'Golf Club' },
    { value: 'Luxury Hotel', label: 'Luxury Hotel' },
    { value: 'Landmark Hotel', label: 'Landmark Hotel' },
    { value: 'City Hall', label: 'City Hall' },
  ];

  const [selectedTags, setSelectedTags] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [venuesData, setVenuesData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState(null);

  const [selectedVenues, setSelectedVenues] = useState([]);

  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  const handleOptionClick = (option) => {
    if (selectedTags.includes(option.value)) {
      setSelectedTags(selectedTags.filter((tag) => tag !== option.value));
    } 
    
    else {
      setSelectedTags([...selectedTags, option.value]);
    }
  };

  const renderSelectedTags = () => {
    const labels = selectedTags
      .map((tagValue) => {
        const option = options.find((opt) => opt.value === tagValue);
        return option ? option.label : tagValue;
      })
      .join(', ');
    return labels || 'Select venue types...';
  };

  const handleExploreVenues = async () => {
    if (selectedTags.length === 0) {
      alert('Please select at least one tag.');
      return;
    }
    setIsLoading(true);
    setApiError(null);

    // Build query string from selected tags
    const queryParams = selectedTags
      .map((tag) => `tags=${encodeURIComponent(tag)}`)
      .join('&');

    try {
      const response = await fetch(`https://junbispark.net/search_venues_by_tags?${queryParams}`);
      const data = await response.json();
      if (data.status === 'success') {
        setVenuesData(data.venues);
      } else {
        setApiError(data.message || 'Error fetching venues.');
      }
    } catch (error) {
      console.error('Error fetching venues:', error);
      setApiError('Error fetching venues. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleVenueSelection = (venueName) => {
    if (selectedVenues.includes(venueName)) {
      setSelectedVenues(selectedVenues.filter((name) => name !== venueName));
    } else {
      setSelectedVenues([...selectedVenues, venueName]);
    }
  };

  const handleProceedToForecast = () => {
    if (selectedVenues.length === 0) {
      alert('Please select at least one venue to proceed.');
      return;
    }

    navigate(`/distance/${user_id}`, { state: { selectedVenues } });
  };

  return (
    <div className="tags-matching-page-container">
      <header className="logo">
        <a href="/">BestDay2Marry</a>
      </header>
      <div className="tags-matching-container">
        <div className="tags-matching-header">
          <h1>Choose Your Dream Venue for Wedding</h1>
          <p>
            Below is a list of venue types our product offers. Please choose the tags you would enjoy the most.
          </p>
        </div>
        <div className="custom-tags-selector">
          <div className="selected-tags-field" onClick={toggleDropdown}>
            {renderSelectedTags()}
            <span className="dropdown-arrow">&#9662;</span>
          </div>
          {isDropdownOpen && (
            <div className="options-container">
              {options.map((option) => (
                <div
                  key={option.value}
                  className={`option-item ${selectedTags.includes(option.value) ? 'option-selected' : ''}`}
                  onClick={() => handleOptionClick(option)}
                >
                  {option.label}
                </div>
              ))}
            </div>
          )}
        </div>
        <button type="button" className="continue-button" onClick={handleExploreVenues}>
          Explore Venues
        </button>
      </div>
      {isLoading && <p>Loading venues...</p>}
      {apiError && <p className="error">{apiError}</p>}
      {venuesData.length > 0 && (
        <>
          <div className="venues-bucket">
            {venuesData.map((venue, index) => {
              const details = venueDetailsMapping[venue.name];
              if (!details) return null;
              return (
                <div
                  key={index}
                  className={`venue-card ${selectedVenues.includes(venue.name) ? 'selected' : ''}`}
                  onClick={() => handleVenueSelection(venue.name)}
                >
                  <img src={details.imageUrl} alt={details.title} className="venue-image" />
                  <div className="card-content">
                    <h3 className="venue-title">{details.title}</h3>
                    <p className="venue-caption">{details.caption}</p>
                  </div>
                </div>
              );
            })}
          </div>
          <button type="button" className="proceed-button" onClick={handleProceedToForecast}>
            Next Steps
          </button>
        </>
      )}
    </div>
  );
};

export default TagsMatching;
