import React, { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import '../styles/PaymentSuccess.css';

function PaymentSuccess() {
  const navigate = useNavigate();
  const { user_id } = useParams();
  const [searchParams] = useSearchParams();
  const accountType = searchParams.get('account_type');

  useEffect(() => {
      const timer = setTimeout(() => {
        if (accountType === "business") {
          navigate(`/business_plan/${user_id}`);
        }
  
        else {
          navigate(`/matching_flow/${user_id}`);
        }
      }, 10000);
      return () => clearTimeout(timer);
    }, [navigate, user_id, accountType]);

  return (
    <div className="payment-success-container">
      <div className="logo">BestDay2Marry</div>
      <div className="payment-message">
        <h1>Payment Failure!</h1>
        <p>Your payment has failed for some reason. Please try again.</p>
        <p>You will be redirected shortly to the payment proccess.</p>
      </div>
      <div className="spinner"></div>
    </div>
  );
}

export default PaymentSuccess;
