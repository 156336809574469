import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/SignUpPage.css'; 
import Cookies from "js-cookies";
import { auth } from "../config/firebase";
import { signInWithEmailAndPassword} from "firebase/auth";


/*

revist this code when improving authentication

*/


const SignUpPage = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [accountType, setAccountType] = useState('couple');
    const [usernameError, setUsernameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const navigate = useNavigate();

    //const {user_id, setUserId} = useState('');
    const get_session = async (user_id) => {
        try {
            await signInWithEmailAndPassword(auth, email, password).then(
                async (userCredential) => {
                    const user = userCredential.user;
                    console.log()
                    return user.getIdToken();
                }).then(
                    (idToken) => {
                        fetch("https://junbispark.net/create_signup_cookie/?idtoken="+encodeURIComponent(idToken),{
                            method: 'POST',
                            headers: {
                                'Content-type':'application/json',
                                'Access-Control-Allow-Credentials':true,
                            },
                            credentials:'include'
                        }).then(
                            (response) => {
                                return response.json();
                            }
                        ).then(
                            (response) => {
                                console.log("get_session", response)
                                if (response['status'] === 'success') {
                                    // const user_id_response = response['user_id'];
                                    // setUserId(user_id_response)
                                    // console.log(response['cookie'])
                                    Cookies.setItem('session_cookie', response['cookie'], response['time_limit_seconds'])
                                    navigate(`/user_verification/${user_id}`);

                                } else {
                                    setEmailError('An account with this email already exists.');
                                }
                            }
                        )
                    }
                )    
        } catch (err) {
            console.log(err);
        }
    };

    const signUp = async () => {
        try {
            const data = {
                username: username,
                email: email,
                pwd: password,
                account_type: accountType
            };

            const url = "https://junbispark.net/sign_up_user/";
            const options = {
                method: 'POST',
                headers: { "content-type": "application/json" },
                body: JSON.stringify(data)
            };

            await fetch(url, options)
                .then(response => response.json())
                .then(async (data) => {
                    let user_id = data.user_id;
                    if (data['status'] === 'success') {
                        localStorage.setItem('accountType', accountType); // setting local storage to hold account type to help route users
                        get_session(user_id);
                    } 
                    
                    else {
                        setEmailError('An account with this email already exists.');
                    }

                    const verifyUrl = `https://junbispark.net/email_verification/?user_id=${user_id}`;
                    await fetch(verifyUrl)
                        .then((data) => data.json());
                })
                .catch((error) => {
                    console.error("error", error);
                });
        } catch (err) {
            console.log(err);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        let valid = true;
        if (!username) {
            setUsernameError('Username is required');
            valid = false;
        } 
        
        else {
            setUsernameError('');
        }

        if (!email) {
            setEmailError('Email is required');
            valid = false;
        } 
        
        else {
            setEmailError('');
        }

        if (!email.match(/^\S+@\S+$/)) {
            setEmailError('Invalid email address');
            valid = false;
        }

        if (!password) {
            setPasswordError('Password is required');
            valid = false;
        } 
        
        else {
            setPasswordError('');
        }

        if (valid) {
            console.log('Form submitted');
            signUp();
        }
    };

    /*const handleCancel = () => {
        navigate('/signin'); 
    }*/

    useEffect(() => {
        document.body.classList.add('sign-up-body');
        return () => {
          document.body.classList.remove('sign-up-body');
        };
      }, []);

    return (
        <body className="sign-up-body">
        <div className="sign-in-container">
            <div className="logo"><a href="/">BestDay2Marry</a></div>
            <div className="sign-in-form">
                <h1>Sign Up</h1>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="Username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            className={usernameError ? 'error' : ''}
                        />
                        {usernameError && <div className="error-message">{usernameError}</div>}
                    </div>
                    <div className="form-group">
                        <input
                            type="email"
                            placeholder="Email address"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className={emailError ? 'error' : ''}
                        />
                        {emailError && <div className="error-message">{emailError}</div>}
                    </div>
                    <div className="form-group">
                        <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className={passwordError ? 'error' : ''}
                        />
                        {passwordError && <div className="error-message">{passwordError}</div>}
                    </div>
                    <div className="form-group">
                        <label>Account Type:</label>
                        <div className="account-type-options">
                            <label>
                                <input
                                    type="radio"
                                    name="accountType"
                                    value="couple"
                                    checked={accountType === 'couple'}
                                    onChange={(e) => setAccountType(e.target.value)}
                                />
                                Couple
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="accountType"
                                    value="business"
                                    checked={accountType === 'business'}
                                    onChange={(e) => setAccountType(e.target.value)}
                                />
                                Business
                            </label>
                        </div>
                    </div>
                    <button type="submit" className="submit-button">Submit</button>
                    {/* <button type="button" className="cancel-button" onClick={handleCancel}>Cancel</button> */}
                    <div className="sign-up-container">
                        <p id="sign-up-msg">Already have an account?&nbsp;<a href="/signin" className="sign-up-link">Sign In</a></p>
                    </div>
                </form>
            </div>
        </div>
        </body>
    );
};

export default SignUpPage;
