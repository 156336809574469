export const venueDetailsMapping = {
    'Alila Napa Valley': {
        imageUrl: 'https://d2mo2a5fvrklap.cloudfront.net/app/uploads/sites/13/2022/08/10193225/napavalley-weddingsandevents-hero-desktop.jpg',
        caption: 'Tucked in the heart of wine country, Alila Napa Valley features scenic landscapes and rich culture and history.',
        title: 'Alila Napa Valley',
        latitude: 38.5093871,
        longitude: -122.478475
    },

    'Alila Ventana Big Sur': {
        imageUrl: 'https://assets.hyatt.com/content/dam/hyatt/hyattdam/images/2019/02/20/1257/Ventana-Big-Sur-an-Alila-Resort-P015-The-Sur-House-Terrace.jpg/Ventana-Big-Sur-an-Alila-Resort-P015-The-Sur-House-Terrace.16x9.jpg',
        caption: 'Ventana Big Sur, a luxury coastal CA resort near Monterey, offers luxury accommodations and unique experiences along the dramatic California coastline',
        title: 'Alila Ventana Big Sur',
        latitude: 36.2303603,
        longitude: -121.7633548
    },

    'Auberge du Soleil': {
        imageUrl: 'https://assets.simpleviewinc.com/simpleview/image/upload/crm/napavalley/RWeill_Version1_DJI_0689_sm_9DFF6185-9AB7-4EC2-90F3AD14A92B3869_615a355e-8f1d-49fc-9ad542b16e4ba201.jpg',
        caption: 'Renowned for its culinary roots, spectacular views and exceptional service, Auberge du Soleil is dedicated to ensuring each guest experience is unique',
        title: 'Auberge du Soleil',
        latitude: 38.49172736736797,
        longitude: -122.40751595973971
    },

    'Avalon Hotel ': {
        imageUrl: 'https://www.avalon-hotel.com/wp-content/uploads/2018/06/APS_Photoshoot_June_2018_Presidio_Courtyard_Pool_04_WEB_R-e1548201969107.jpg',
        caption: 'Our unique boutique hotel tucked away in the heart of the historic downtown Palm Springs, is a haven of beauty that perfectly blends the sophistication of a ...',
        title: 'Avalon Hotel',
        latitude: 33.8176342,
        longitude: -116.54818108933718
    },

    "Beaulieu Garden": {
        imageUrl: 'https://bluenoteweddings.com/wp-content/uploads/2023/10/B_GBeaulieuGarden63.jpg',
        caption: 'Beaulieu Garden is a private, family‑owned estate and one of the oldest wine‑producing vineyards in the Napa Valley.',
        title: 'Beauleiu Garden',
        latitude: 38.4515231,
        longitude: -122.4160396
    },

    "Bighorn Golf Club": {
        imageUrl: 'https://www.bighorngolf.com/site/assets/files/1017/clubhouse-6.jpg',
        caption: "The most innovative, imaginative club in the world. Innovative enhancements, cutting-edge approach define BIGHORN's more than 30-year success.",
        title: 'Bighorn Golf Club',
        latitude: 33.67789087166108,
        longitude: -116.40766014179879
    },

    "Calamigos Ranch": {
        imageUrl: 'https://d2mqtslxlneuif.cloudfront.net/redwood_room_main_f2a42517b0.webp',
        caption: 'Nestled in the heart of Malibu, Calamigos Ranch offers a unique destination for indoor and outdoor ceremonies across 5,500 acres of pristine beauty in the Santa ...',
        title: 'Calamigos Ranch',
        latitude: 34.09174470000001,
        longitude: -118.81784381
    },

    "Cameo Beverly Hills": {
        imageUrl: 'https://www.hilton.com/im/en/LAXMCOL/19149547/exterior-day-v5s-nomrc.jpg?impolicy=crop&cw=5000&ch=2799&gravity=NorthWest&xposition=0&yposition=138&rw=768&rh=430',
        caption: 'Experience the energy and tranquility of Beverly Hills at Cameo. Discover a hidden oasis where luxury meets escapism. Your part of Beverly Hills awaits.',
        title: 'Cameo Beverly Hills',
        latitude: 34.05536600000001,
        longitude: -118.39855030566966

    },

    "Casa Romantica Cultural Center and Gardens": {
        imageUrl: 'https://artguide.artforum.com/uploads/location.000/id20820/location00_1064x.jpg',
        caption: 'Nestled in a beautiful 1927 Spanish Colonial Revival building owned by the city of San Clemente, we offer a dynamic space where visitors of all ages can explore ...',
        title: 'Casa Romantica Cultural Center and Gardens',
        latitude: 33.4218286,
        longitude: -117.620525628471
    },

    "Chateau Marmont": {
        imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/ChateauMarmont_01.jpg/1200px-ChateauMarmont_01.jpg',
        caption:  "Located in West Hollywood, California, André Balazs' Chateau Marmont features luxury bungalows and suites reminiscent of old Hollywood.",
        title: 'Chateau Marmont',
        latitude: 34.098249749999994,
        longitude: -118.36840487975618
    },

    "Estancia La Jolla Hotel and Spa": {
        imageUrl: 'https://media.cntraveler.com/photos/6670920a46d019ab4a2aba9e/16:9/w_2560,c_limit/Estancia%20La%20Jolla%20Hotel%20&%20Spa_Estancia%20La%20Jolla%20Courtyard.jpg',
        caption: "A hacienda-style resort nestled between San Diego's seaside villages of La Jolla and Del Mar. Exuding warm service and the sun-drenched charm of rancho- ...",
        title: 'Estancia La Jolla Hotel and Spa',
        latitude: 32.8847459,
        longitude: -117.24515656919587
    },

    "Fairmont Grand Del Mar": {
        imageUrl: 'https://dynamic-media-cdn.tripadvisor.com/media/photo-o/2b/c6/8f/08/resort-pool.jpg?w=700&h=-1&s=1',
        caption: 'Experience a memorable escape at our luxury Fairmont San Diego hotel. Enjoy the exquisite dining and unrivaled service of our 5-star golf and spa resort.',
        title: 'Fairmont Grand Del Mar',
        latitude: 32.9384035,
        longitude: -117.1975421
    },

    "Fairmont Sonoma Mission Inn ": {
        imageUrl: 'https://www.fairmont-sonoma.com/content/uploads/2022/04/FairmontSonoma_BreezewayLawn_0229_v4.jpg',
        caption: 'Located just an hour north of San Francisco, our award-winning hotel is located in an impeccable setting in which to relish the enviable Sonoma lifestyle.',
        title: 'Fairmont Sonoma Mission Inn',
        latitude: 38.31319395,
        longitude: -122.48254647691942
    },

    "Four Seasons Hotel Los Angeles at Beverly Hills": {
        imageUrl: 'https://cdn.kiwicollection.com/media/property/PR000739/xl/000739-01-Exterior-Four-Seasons-Los-Angeles-Beverly-Hills.jpg?cb=1565283186',
        caption: 'Experience luxury at Four Seasons Hotel Los Angeles at Beverly Hills. Enjoy a resort-style pool, award-winning Italian cuisine, and a world-class spa.',
        title: 'Four Seasons Hotel Los Angeles at Beverly Hills',
        latitude: 34.0729537,
        longitude: -118.38921393082708
    },

    "Gabriola Island": {
        imageUrl: 'https://gulfislandseaplanes.com/wp-content/uploads/2022/01/visit-gabriola-island-with-us-gulf-island-seaplanes.png',
        caption: 'Gabriola offers endless opportunities for relaxation, recreation, arts and culture, shopping, local food, lodging, transportation options and more!',
        title: 'Gabriola Island',
        latitude: 41.13149076554121,
        longitude: -123.423152352
    },

    "Hotel Casa Del Mar": {
        imageUrl: 'https://s3.amazonaws.com/uploads.edwardthomasco.com/uploads/sites/2/2024/10/28220541/CDM_Exterior_-Hero-3x2_FINAL-scaled.jpg',
        caption: 'A boutique luxury hotel in Santa Monica, where every moment mesmerizes. Dramatic ocean views, attentive service, and thoughtful amenities.',
        title: 'Hotel Casa Del Mar',
        latitude: 34.0064188,
        longitude: -118.49126582367057
    },

    "Hyatt Regency Sonoma Wine Country": {
        imageUrl: 'https://assets.hyatt.com/content/dam/hyatt/hyattdam/images/2022/08/17/1305/SONOM-P0168-Hotel-Exterior-Front-Drive.jpg/SONOM-P0168-Hotel-Exterior-Front-Drive.16x9.jpg',
        caption: 'Retreat to Hyatt Regency Sonoma Wine Country. Our hotel in Santa Rosa offers spacious rooms and exquisite suites with scenic views, an outdoor pool, ...',
        title: 'Hyatt Regency Sonoma Wine Country',
        latitude: 38.4356668,
        longitude: -122.7193679
    },

    "Inn at the Mission San Juan Capistrano, Autograph Collection": {
        imageUrl: 'https://www.innatthemissionsjc.com/resourcefiles/homeimages/innatthemission-weddings.jpg?version=2022025114740',
        caption: 'Inn at the Mission San Juan Capistrano, Autograph Collection creates a place for rest and renewal that has been true to our location for ages. Stay in hacienda ...',
        title: 'Inn at the Mission San Juan Capistrano, Autograph Collection',
        latitude: 33.502292100000005,
        longitude: -117.66043660396352
    },

    "Joshua Tree National Park": {
        imageUrl: 'https://images.squarespace-cdn.com/content/v1/590d435517bffcc3aa0b3ceb/d9da6e22-56bb-4020-a43c-b274287292aa/Joshua-Tree-Elopement--Sarah-Linda-Photography-1.jpg',
        caption: 'Two distinct desert ecosystems, the Mojave and the Colorado, come together in Joshua Tree National Park. A fascinating variety of plants and ...',
        title: 'Joshua Tree National Park',
        latitude: 34.1286515,
        longitude: -116.03755
    },

    "La Quinta Resort ": {
        imageUrl: 'https://assets.simpleviewinc.com/simpleview/image/upload/c_limit,q_75,w_1200/v1/crm/palmsprings/100-3-23476_jpeg-432e6361a8d10bc_432ef598-0e29-0689-3bf8ba48e15d13a4.jpg',
        caption: 'La Quinta Resort & Club is the premier Palm Springs luxury hotel, featuring a world-class Spa, PGA West golf courses, 41 pools, yoga retreats, and more.',
        title: 'La Quinta Resort',
        latitude: 33.68881145,
        longitude: -116.31104275098629
    },

    "La Venta Inn": {
        imageUrl: 'https://i0.wp.com/palosverdesmagazine.com/wp-content/uploads/2023/08/unnamed.jpg?fit=2400%2C1599&ssl=1',
        caption: "While at La Venta Inn, your guests hear the Spanish fountain's gentle babble, taste world-class cuisine, and see ocean views through a space rolling with ...",
        title: 'La Venta Inn',
        latitude: 33.79435797121365,
        longitude: -118.40064911138424
    },

    "Lido House, Autograph Collection": {
        imageUrl: 'https://dynamic-media-cdn.tripadvisor.com/media/photo-o/2e/60/50/8d/exterior.jpg?w=700&h=-1&s=1',
        caption: 'A boutique hotel reimagining the authentic beach house experience on Lido Isle in Newport Beach. Situated near Lido Marina Village and Balboa Peninsula.',
        title: 'Lido House, Autograph Collection',
        latitude: 33.61661225,
        longitude: -117.92911693843786
    },

    "Lorimar Vineyards And Winery": {
        imageUrl: 'https://prioritywinepass.com/wp-content/uploads/2016/03/951620330.jpg',
        caption: 'A unique Temecula Winery that provides the perfect Temecula wine tasting experience with award-winning wines, a passionate staff, art gallery, and live ...',
        title: 'Lorimar Vineyards And Winery',
        latitude: 33.4582064,
        longitude: -117.0868253
    },

    "Malibu Rocky Oaks Estate Vineyard": {
        imageUrl: 'https://na.rdcpix.com/c2a46bc529c1763a308f6fdf9f21713aw-c711698565srd_q80.jpg',
        caption: 'This 37-acre plot private vineyard has 360-degree views of the Santa Monica Mountains and rolling hillsides.',
        title: 'Malibu Rocky Oaks Estate Vineyard',
        latitude: 34.0299853,
        longitude: -118.7977082
    },

    "Marina del Rey Marriott": {
        imageUrl: 'https://cache.marriott.com/is/image/marriotts7prod/mc-laxmb-mc-laxmb-wedding--53074-12167:Classic-Hor?wid=1300&fit=constrain',
        caption: 'Discover our pet-friendly hotel in Marina del Rey, welcoming both you and your furry friend for an unforgettable stay by the ocean.',
        title: 'Marina del Rey Marriott',
        latitude: 33.9821559,
        longitude: -118.46015462554354
    },

    "Marriott Irvine Spectrum": {
        imageUrl: 'https://www.destinationirvine.com/wp-content/uploads/2024/11/Event-Lawn1.webp',
        caption: "Located just steps from Irvine Spectrum Center's top retailers, restaurants, entertainment and Great Park Live; our hotel offers a prime spot to explore.",
        title: 'Marriott Irvine Spectrum',
        latitude: 33.65724295,
        longitude: -117.7478576668594
    },

    "Montage Laguna Beach": {
        imageUrl: 'https://uploads.montage.com/uploads/sites/6/2020/01/09162710/mlb-og.jpg',
        caption: 'Montage Laguna Beach is a luxury resort in Orange County, California, offering premier oceanfront views of the Pacific and world-class amenities.',
        title: 'Montage Laguna Beach',
        latitude: 33.5146337,
        longitude: -117.75672366895611
    },

    "Monterey Marriott": {
        imageUrl: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/545442149.jpg?k=a66ed7c35d88813d673887162c7839c84d268b11c424a979c136d5474d28bb76&o=&hp=1',
        caption: 'Enjoy an idyllic stay at Monterey Marriott. Our hotel in Monterey, CA, overlooks the bay and offers stylish rooms, dining, and extensive event facilities.',
        title: 'Monterey Marriott',
        latitude: 36.60055416326531,
        longitude: -121.89494887755102
    },

    "Ojai Valley Inn": {
        imageUrl: 'https://media.cntraveler.com/photos/5a8e0b86ad2a7e3780f2e244/16:9/w_2560,c_limit/Ojai-Valley-Inn-and-Spa_2018_Hero_Ojai-Valley-Inn.jpg',
        caption: 'WELCOME TO THE VALLEY. Home to rich landscapes, artisans, adventurers, and a flourishing dining scene, the Ojai Valley offers small-town charm and expansive ..',
        title: 'Ojai Valley Inn',
        latitude: 34.441393,
        longitude: -119.2585794
    },

    "Park Hyatt Aviara Resort": {
        imageUrl: 'https://media.cntraveler.com/photos/608c4dc87dd7a18f7a9d427d/master/pass/Park-Hyatt-Aviara-Courtyard-Fountain.jpg',
        caption: "Plan your getaway to the 5-Star, luxury golf resort, Park Hyatt Aviara. Take in the natural beauty of California's coast, during your stay in Carlsbad, ...",
        title: 'Park Hyatt Aviara Resort',
        latitude: 33.09932275,
        longitude: -117.28600360014619
    },

    "Parker Palm Springs": {
        imageUrl: 'https://static-new.lhw.com/HotelImages/Final/LW2762/lw2762_87599804_720x450.jpg',
        caption: 'Are you searching for the best hotel Palm Springs offers? Wake up and start dreaming at Parker Palm Springs, an exclusive boutique hotel in Palm Springs.',
        title: 'Parker Palm Springs',
        latitude: 33.79613,
        longitude: -116.50045619660506
    },

    "Pebble Beach Resorts": {
        imageUrl: 'https://www.pebblebeach.com/content/uploads/20160811-cam-timeout.jpg',
        caption: 'Experience the wonders of Pebble Beach. World-famous golf. World-class accommodations. Out-of-this-world scenery.',
        title: 'Pebble Beach Resorts',
        latitude: 36.5699003,
        longitude: -121.9399807
    },

    "Rosewood Miramar Beach": {
        imageUrl: 'https://durpettievents.com/wp-content/uploads/2024/03/rosewood-miramar-beach-resort-wedding-venue-california-8.webp',
        caption: ' Set on an exclusive coastline, the Forbes Five-Star Hotel, Rosewood Miramar Beach offers a captivating estate-style atmosphere & the finest ...',
        title: 'Rosewood Miramar Beach',
        latitude: 34.421191,
        longitude: -119.628281
    },

    "Rosewood Sand Hill": {
        imageUrl: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/552493020.jpg?k=3a603fa3c3d1c6d6bd29225bd1b7294a02b7a9189f4695e99939b58328419ee0&o=&hp=1',
        caption: 'Set in the heart of Silicon Valley, the Rosewood Sand Hill hotel charms both business & leisure travelers with resort amenities & a relaxed translation of ...',
        title: 'Rosewood Sand Hill',
        latitude: 37.4195318,
        longitude: -122.21254224864417
    },

    "SLS Hotel Beverly Hill": {
        imageUrl: 'https://cache.marriott.com/is/image/marriotts7prod/lc-laxls-ladida-cabanas--40781:Feature-Hor?wid=1920&fit=constrain',
        caption: "Ultra-modern design meets classic elegance in 297 luxurious rooms and suites. It's everything you'd expect from an iconic Beverly Hills hotel, and so much more.",
        title: 'SLS Hotel Beverly Hill',
        latitude: 34.0595988,
        longitude: -118.376192
    },

    "San Diego Botanic Garden": {
        imageUrl: 'https://i0.wp.com/sdbg.org/wp-content/uploads/2023/03/208_JSP_AlannaRyan_051422-scaled-e1720550268159.jpg?fit=2560%2C1659&ssl=1',
        caption: 'Established in 1970, San Diego Botanic Garden (SDBG) is a 37-acre urban oasis that creates, shares and applies plant wisdom with people of all backgrounds.',
        title: 'San Diego Botanic Garden',
        latitude: 33.055376,
        longitude: -117.278982
    },

    "San Francisco City Hall": {
        imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/1/1f/San_Francisco_City_Hall_2.JPG',
        caption: "Known as the People's Palace, San Francisco City Hall is the seat of government for the City and County of San Francisco.",
        title: 'San Francisco City Hall',
        latitude: 37.778597,
        longitude: -122.418355
    },

    "San Ysidro Ranch": {
        imageUrl: 'https://dynamic-media-cdn.tripadvisor.com/media/photo-o/02/47/12/3d/property-ocean-view.jpg?w=700&h=-1&s=1',
        caption: 'Tucked away in the leafy foothills of Montecito, CA, San Ysidro Ranch offers a unique blend of privacy, peace, and tranquility in an idyllic setting.',
        title: 'San Ysidro Ranch',
        latitude: 34.4465268,
        longitude: -119.623169
    },

    "Shutters on the Beach": {
        imageUrl: 'https://images.ctfassets.net/fr52oodlzhve/5YLgFfjwbl73zSHtFpKypE/77f6a078f220955c39df86a46972333d/Shutters_PoolMidday-0006_v4.jpg',
        caption: 'Set on a stretch of coastline near LA, Shutters is a luxury Santa Monica hotel that blends modern comfort with the ambiance of a classic SoCal retreat.',
        title: 'Shutters on the Beach',
        latitude: 34.00706205,
        longitude: -118.49171767648926
    },

    "Terranea Resort": {
        imageUrl: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/15842660.jpg?k=8b0c94993afebb3cdc9ec2c1b87c1776beac816563eea554ce39f10478222d09&o=&hp=1',
        caption: 'With luxury accommodations, impeccable service, and 270-degree panoramic views, Terranea features a one-of-a-kind immersive retreat blending comfort and ...',
        title: 'Terranea Resort',
        latitude: 33.738649,
        longitude: -118.3962257
    },

    "The Beverly Hills Hotel": {
        imageUrl: 'https://media.cool-cities.com/beverly_hills_hotel001_pr_f_mob.jpg?h=730',
        caption: "Discover The Beverly Hills Hotel, our legendary Los Angeles hotel. Set on Sunset Boulevard, it's an ever-evolving part of Hollywood history.",
        title: 'The Beverly Hills Hotel',
        latitude: 34.08171455,
        longitude: -118.4137967466237
    },

    "The Clancy, Autograph Collection": {
        imageUrl: 'A vibrant hotel in SoMa San Francisco. Immerse yourself in endless possibilities as you explore this one-of-a-kind area, with easy access to Oracle Park.',
        caption: 'https://cache.marriott.com/content/dam/marriott-renditions/SFOAW/sfoaw-seven-square-8187-hor-clsc.jpg?output-quality=70&interpolation=progressive-bilinear&downsize=1300px:*',
        title: 'The Clancy, Autograph Collection',
        latitude: 37.7858427,
        longitude: -122.3969741
    },

    "The Lodge at Torrey Pines": {
        imageUrl: 'https://www.lodgetorreypines.com/sites/default/files/2021-06/Hero_homepageb.jpg',
        caption: 'Experience the luxury of Five Diamond Torrey Pines Resort Hotel in La Jolla, California. With views of the world-renowned Torrey Pines Golf Course and the ...',
        title: 'The Lodge at Torrey Pines',
        latitude: 32.89689585,
        longitude: -117.24553156407407
    },

    "The O’Donnell House at The Willows Historic Palm Springs Inn": {
        imageUrl: 'https://odonnellhouse.com/wp-content/uploads/2019/04/pg_2019_12.jpg',
        caption: "Nestled at the base of Mount San Jacinto lies The Willows Historic Palm Springs Inn and the famous O'Donnell House. The house, designed by architect William ...",
        title: 'The O’Donnell House at The Willows Historic Palm Springs Inn',
        latitude: 33.8231892,
        longitude: -116.55047968726734
    },

    "The Resort at Pelican Hill": {
        imageUrl: 'https://www.pelicanhill.com/images/world-away-scaled.jpg',
        caption: 'Enjoy a luxury getaway at The Resort at Pelican Hill, a Five-Star hotel in Newport Beach with Italian-inspired architecture and Pacific Ocean views.',
        title: 'The Resort at Pelican Hill',
        latitude: 33.5862749,
        longitude: -117.84204990647704
    },

    "The Ritz-Carlton Bacara, Santa Barbara": {
        imageUrl: 'https://cache.marriott.com/content/dam/marriott-renditions/SBARZ/sbarz-oceanfront-7372-hor-feat.jpg?output-quality=70&interpolation=progressive-bilinear&downsize=1920px:*',
        caption: 'Experience the epitome of luxury at The Ritz-Carlton Bacara, our Santa Barbara, CA, resort. Nestled on the picturesque California coast, ...',
        title: 'The Ritz-Carlton Bacara, Santa Barbara',
        latitude: 34.43384115,
        longitude: -119.91823037878436
    },

    "The Ritz-Carlton, Laguna Niguel": {
        imageUrl: 'https://cache.marriott.com/content/dam/marriott-renditions/SNARZ/snarz-blu-0024-hor-wide.jpg?output-quality=70&interpolation=progressive-bilinear&downsize=1336px:*',
        caption: 'Stay at The Ritz-Carlton, Laguna Niguel resort in California, and enjoy direct beach access, ocean-view hotel rooms, locally inspired dining and a luxury ...',
        title: 'The Ritz-Carlton, Laguna Niguel',
        latitude: 33.476093,
        longitude: -117.718909
    },

    "The Ritz-Carlton, Lake Tahoe": {
        imageUrl: 'https://dynamic-media-cdn.tripadvisor.com/media/photo-o/16/47/9d/4a/hotel-exterior.jpg?w=700&h=-1&s=1',
        caption: 'From award-winning dining, the 17,000 square foot spa and magnificent mid-mountain views, The Ritz-Carlton, Lake Tahoe, is the indisputable choice among ...',
        title: 'The Ritz-Carlton, Lake Tahoe',
        latitude: 39.2670733,
        longitude: -120.12762063914838
    },

    "The Ritz-Carlton, San Francisco": {
        imageUrl: 'https://www.argcs.com/wp-content/uploads/2016/06/Ritz-Carlton_01.jpg',
        caption: 'Create landmark memories from our iconic luxury hotel in San Francisco located in the prestigious neighborhood of Nob Hill.',
        title: 'The Ritz-Carlton, San Francisco',
        latitude: 37.7914692,
        longitude: -122.4073249
    },

    "The Westin Carlsbad Resort ": {
        imageUrl: 'https://cache.marriott.com/content/dam/marriott-digital/wi/us-canada/hws/s/sanwc/en_us/photo/unlimited/assets/sanwc-swimming-pool-3450.jpg',
        caption: 'Are you looking for a luxury retreat with spa in San Diego, CA? Contact The Westin Carlsbad Resort & Spa today to book your stay.',
        title: 'The Westin Carlsbad Resort',
        latitude: 33.13149076554121,
        longitude: -117.31054946741273
    },

    "Vancouver Island": {
        imageUrl: 'https://image-tc.galaxy.tf/wijpeg-bsqpyd3xh4nurl5vinwki7qq/blog-detail_og-image.jpg',
        caption: "Vancouver Island is the world's 43rd largest island, Canada's 11th largest island, and Canada's second most populous island after the Island of Montreal.",
        title: 'Vancouver Island',
        latitude: 41.13149076554121,
        longitude: -123.423152352
    }, 

    "Whidbey Island": {
        imageUrl: 'https://afar.brightspotcdn.com/dims4/default/277b871/2147483647/strip/false/crop/2000x1333+0+0/resize/1486x990!/quality/90/?url=https%3A%2F%2Fk3-prod-afar-media.s3.us-west-2.amazonaws.com%2Fbrightspot%2F4b%2F7e%2F58d2ca4a77ca17bb6ddef18367c4%2Foriginal-credit-20michaelstadler-deceptionpass.jpg',
        caption: 'Coupeville and Central Whidbey Island offer peaceful landscapes and opportunities for biking, hiking, kayaking, sailing, or just breathing in the fresh sea air ...',
        title: 'Whidbey Island',
        latitude: 41.13149076554121,
        longitude: -121.31054946741273
    }
};

    
    
    
    
    
    
    