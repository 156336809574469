import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useParams } from 'react-router-dom';
import {useAuth} from '../utils/authprovider';
import { fetchVenueForUser, saveVenueToUser } from '../utils/queries';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import IconPin from '../assets/icon-pin.png';

import {
    saveUnitsToBackend
} from './ExpandedTracker';

import { 
    fetchUnitSettingsForUser,
} from '../utils/queries';

import {
    TEMP_UNIT_KEY,
    FAHRENHEIT,
    IMPERIAL, METRIC, IMPERIAL_UNITS, METRIC_UNITS
} from '../misc/constants';

import '../styles/SettingsPage.css';
import SideMenu from '../components/SideMenu';
import { getVenuesList } from './VenueSelection';
import Cookies from "js-cookies";



const customIcon = L.icon({
    iconUrl: IconPin,
    iconSize: [48, 48], 
    iconAnchor: [12, 41], // Point of the icon which will correspond to marker's location
    popupAnchor: [1, -34], // Point from which the popup should open relative to the iconAnchor
    // shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    // shadowUrl: iconShadow,
    shadowSize: [41, 41]
});

const SettingsPage = ({settings, activeVenue, onVenueSelect}) => {

    const [location, setLocation] = useState(null);
    const { user_id } = useParams();
    const [unitSystem, setUnitSystem] = useState(IMPERIAL);
    const [,setUnitSettings] = useState(IMPERIAL_UNITS);

    const [venueList, setVenueList] = useState([]);

    const [selectedVenue, setSelectedVenue] = useState('');


    useEffect(() => {
        document.body.classList.add('override-body');
    
        return () => {
          document.body.classList.remove('override-body');
        };
    }, []);

    // Fetch the current selected venue
    useEffect(() => {
        const fetchVenue = async () => {
            const venueForUser = await fetchVenueForUser(user_id);
            setSelectedVenue({value: venueForUser, label: venueForUser});
            console.log('SET SELECTED VENUE:', venueForUser);
        };
        fetchVenue();
    },)


    useEffect(() => {
        const fetchUnits = async () => {
            const units = await fetchUnitSettingsForUser(user_id);
            if (!units) {
                console.log('Unit settings fetch returned null, using default units');
                return;
            }
            setUnitSettings(units);
            if (units[TEMP_UNIT_KEY] === FAHRENHEIT) {
                setUnitSystem(IMPERIAL);
            } else {
                setUnitSystem(METRIC);
            }
            console.log('SET UNITS:', units);
        };
        const getLocation = async () => {
            try {
                console.log("calling loc");
                const session_cookie = Cookies.getItem("session_cookie");
                const response = await fetch(
                  `https://junbispark.net/get_user_location/?user_id=${user_id}&session_cookie=${session_cookie}`
                );
            
                if (!response.ok) {
                  // Handle non-2xx status codes
                  throw new Error(`Server responded with status ${response.status}`);
                }
            
                const data = await response.json();
            
                setLocation({
                  name: data.name,
                  lat: data.lat,
                  lon: data.lon,
                });
              } catch (error) {
                console.error("Error fetching location:", error);
                // Optionally, set some error state in React to display a user-friendly message
              }
            };
        getLocation();
    
        fetchUnits();
    },);

    useEffect(() => {
        const getVenues = async () => {
            // const data = await fetch(`https://junbispark.net/get_all_venues/?user_id=${user_id}`).then((response) => {
            //     return response.json();
            // }).then((data) => {
            //     setData(data['venues'].map((val) => { return <option value={val}>{val}</option> }));
            //     setVenue(data['venues'][0]);
            // });
            const data = await getVenuesList(user_id)

            // USE THIS CODE IF USING A NORMAL <select></select>
            // setVenueList(data['venues'].map((val) => { return <option value={val}>{val}</option> }));

            // USE THIS CODE IF USING react-select
            setVenueList(data['venues'].map((val) => { return {value: val, label: val} }));

            // setVenue(data['venues'][0]);
        }
        getVenues();
    }, [user_id]);

    const handleUnitToggle = async () => {
        let unitsToSaveToBackend = null;
        if (unitSystem === IMPERIAL) {
            setUnitSystem(METRIC);
            setUnitSettings(METRIC_UNITS);
            unitsToSaveToBackend = METRIC_UNITS;
        } else {
            setUnitSystem(IMPERIAL);
            setUnitSettings(IMPERIAL_UNITS);
            unitsToSaveToBackend = IMPERIAL_UNITS;
        }

        await saveUnitsToBackend(user_id, unitsToSaveToBackend);
    };
    const navigate = useNavigate();
    const {logout} = useAuth();
    const handleLogout = async () => {
        try {
            // await fetch('http://localhost:5000/logout', { method: 'POST' });
            // localStorage.removeItem('token');
            logout();

            navigate('/signin');
        } catch (error) {
            console.error('Error logging out:', error);
        }
        console.log('User logged out');
    };


    const handleVenueSelect = async (venue) => {
        console.log('Selected venue', venue)
        await saveVenueToUser(user_id, venue.value)
        setSelectedVenue(venue)
    }

    const renderMapSection = () => {
        return (
            <div className="map-container">
                {location && (
                    <MapContainer
                        center={[location.lat, location.lon]}
                        zoom={4}
                        style={{ height: '400px', width: '400px' }}
                    >

                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                    <Marker position={[location.lat, location.lon]} icon={customIcon}>
                        <Popup>{location.name}</Popup>
                    </Marker>
                </MapContainer>
            )}
          </div>
        );
    };

    const renderSettingsButtons = () => {
        return (
            <div className="buttons-container">
                <button className="toggle" onClick={handleUnitToggle}>
                    {unitSystem === IMPERIAL ? 'Switch to Metric' : 'Switch to Imperial'}
                </button>
                <button className="primary-button" onClick={handleLogout}>Logout</button>
            </div>
        );
    };

    return (
        <div className="settings-page">
            <SideMenu userId={user_id} />
            <div className="settings-content">
                <h1 className="settings-title">Settings Page</h1>
                {renderMapSection()}
                {renderSettingsButtons()}
                <Select
                    value={selectedVenue}
                    options={venueList}
                    onChange={handleVenueSelect}
                />
            </div>
        </div>
    );
};

export default SettingsPage;