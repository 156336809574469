import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer, Label } from 'recharts';

function WeatherChart ({
    data,
    startDate,
    endDate,
    interval,
    unitSettings,
    calculateDomain,
    chartConfig,
    CustomTooltip
}) {
    if (!data || data.length === 0) {
        return null;
    }

    const { dataKey, minKey, maxKey, color, fillId, domainMargin, yAxisLabel } = chartConfig;

    const domain = calculateDomain(data, minKey, maxKey, domainMargin);

    const dateRangeInDays = (endDate - startDate) / (1000 * 60 * 60 * 24);
    const formatDateTick = (value) => {
        const date = new Date(value);
        return (dateRangeInDays <= 1)
            ? date.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' })
            : date.toLocaleDateString(undefined, { month: 'short', day: 'numeric' });
    };

    const yAxisTickFormatter = (value) => {
      const range = domain[1] - domain[0];
      if (range < 1) {
          return value.toFixed(2);
      } 
      
      else {
          return Math.round(value).toString();
        }
    };

    return (
        <ResponsiveContainer width="100%" height={800}>
          <AreaChart data={data} margin={{ top: 16, right: 32, left: 8, bottom: 16 }}>
            <defs>
              <linearGradient id={fillId} x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={color} stopOpacity={1} />
                <stop offset="95%" stopColor={color} stopOpacity={0} />
              </linearGradient>
            </defs>
    
            <XAxis
              dataKey="date"
              axisLine={false}
              interval={interval}
              tickCount={8}
              tickFormatter={formatDateTick}
            />
    
            <YAxis
              yAxisId="left"
              domain={domain} 
              axisLine={false}
              tickFormatter={yAxisTickFormatter}
            >
              <Label
                value={
                  typeof yAxisLabel === 'function'
                    ? yAxisLabel(unitSettings)
                    : yAxisLabel
                }
                angle={-90}
                position="insideLeft"
                style={{ textAnchor: 'middle' }}
              />
            </YAxis>
    
            <Tooltip content={<CustomTooltip minKey={minKey} maxKey={maxKey} />} />
    
            <Area
              yAxisId="left"
              type="monotone"
              dataKey={dataKey}
              stroke={color}
              fillOpacity={1}
              fill={`url(#${fillId})`}
            />
          </AreaChart>
        </ResponsiveContainer>
      );
}

export default WeatherChart;