import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import '../styles/ExpandedTracker.css';
import IconWindLite from '../assets/icon-wind-lite.png';
import IconRainLite from '../assets/icon-rain-lite.png';
import IconTempLite from '../assets/icon-temp-lite.png';
import IconCloudLite from '../assets/icon-cloud-lite.png';
import { fetchVenueForUser } from '../utils/queries';
import { getVenuesList } from './VenueSelection';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

import WeatherChart from '../components/WeatherChart';
import SideMenu from '../components/SideMenu';
import DateFilter from '../components/DateFilter';
import TabSelector from '../components/TabSelector';

import { CHART_CONFIGS } from '../misc/chartConfigs';
import { useForecastData } from '../hooks/useForecastData';
import { useUnitSettings } from '../hooks/useUnitSettings';
import { getWindDirection } from '../utils/weatherUtils';

export const TEMP_UNIT_KEY = 't2m_units';
export const PRECIP_UNIT_KEY = 'precipitation_units';
export const WIND_UNIT_KEY = 'ws2m_units';
export const CELSIUS = 'C';
export const FAHRENHEIT = 'F';
export const INCHES = 'inches';
export const CM = 'cm';
export const MPH = 'mph';
export const KMPH = 'kmph';
export const IMPERIAL = 'imperial';
export const METRIC = 'metric';

const ONE_DAY_FUTURE = 1000 * 60 * 60 * 24;
const ONE_WEEK_FUTURE = 1000 * 60 * 60 * 24 * 7;
const TWO_WEEK_FUTURE = 1000 * 60 * 60 * 24 * 14;
const THREE_WEEK_FUTURE = 1000 * 60 * 60 * 24 * 21;

// Utility function to calculate interval
const calculateInterval = (startDate, endDate) => {
    const diffTime = Math.abs(endDate - startDate);
    const diffDays = Math.ceil(diffTime / (ONE_DAY_FUTURE));
    if (diffDays <= 7) {
        return 0; 
    } 
    
    else if (diffDays <= 30) {
        return 1; 
    } 
    
    else if (diffDays <= 90) {
        return 2;  
    } 
    
    else if (diffDays <= 180) {
        return 5;  
    } 
    
    else if (diffDays <= 365) {
        return 10;  
    } 
    
    else {
        return 20;  
    }
};


export const saveUnitsToBackend = async (userId, units) => {
    try {
        const url = `https://junbispark.net/update_unit_settings/?user_id=${userId}&precipitation_units=${units[PRECIP_UNIT_KEY]}&t2m_units=${units[TEMP_UNIT_KEY]}&ws2m_units=${units[WIND_UNIT_KEY]}`
        
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(units)
        })
        const result = await response.json()
        console.log('SAVING UNITS RESULT', result)
    } catch (error) {
        console.error('Error fetching unit settings for user', userId, ':', error);
    }
    console.log('Successfully saved units to backend:', units);
    return null;
}

const CustomTooltip = ({ active, payload, label, minKey, maxKey }) => {
    if (active && payload && payload.length) {
        const dataPoint = payload[0].payload;
        return (
            <div className="custom-tooltip">
                <p className="label">{`Date: ${label}`}</p>
                <p className="intro">{`${payload[0].name}: ${payload[0].value}`}</p>
                <p className="intro">{`Min: ${dataPoint[minKey]}`}</p>
                <p className="intro">{`Max: ${dataPoint[maxKey]}`}</p>
            </div>
        );
    }
    return null;
};

function ExpandedTracker({ trackers, onSave, settings, activeVenue }) {
    let { user_id } = useParams();
    let { unitSettings } = useUnitSettings(user_id);

    const [startDate, setStartDate] = useState(new Date(new Date().getTime() + TWO_WEEK_FUTURE));
    const [endDate, setEndDate] = useState(new Date(new Date().getTime() + THREE_WEEK_FUTURE));
    const [timeRange, setTimeRange] = useState('1W');

    const { data } = useForecastData(user_id, startDate, endDate, unitSettings);

    const [activeTab, setActiveTab] = useState('temperature');
    const [interval, setInterval] = useState(0);
    const [alerts] = useState([]);
    const [insights, setInsights] = useState(null);
    const [selectedVenue, setSelectedVenue] = useState('');
    const [, setVenueList] = useState([]);

    const calculateDomain = (dataArray, minKey, maxKey, margin = 0) => {
        const minValues = dataArray.map(item => item[minKey]);
        const maxValues = dataArray.map(item => item[maxKey]);

        let minValue = Math.min(...minValues);
        let maxValue = Math.max(...maxValues);

        const adjustedMin = minValue - margin;
        const adjustedMax = maxValue + margin;

        const range = adjustedMax - adjustedMin;

        if (range < 1) {
            minValue = parseFloat(adjustedMin.toFixed(2));
            maxValue = parseFloat(adjustedMax.toFixed(2));
        }

        else {
            minValue = Math.floor(adjustedMin);
            maxValue = Math.ceil(adjustedMax);
            
            minValue = Number.isInteger(minValue) ? minValue : parseFloat(minValue.toFixed(0));
            maxValue = Number.isInteger(maxValue) ? maxValue : parseFloat(maxValue.toFixed(0));
        }

        return [minValue, maxValue];
    };
    
    useEffect(() => {
        async function fetchVenue() {
          const venueForUser = await fetchVenueForUser(user_id);
          setSelectedVenue(venueForUser);
        }
        fetchVenue();
      }, [user_id]);

      useEffect(() => {
        async function getVenues() {
          const data = await getVenuesList(user_id);
          setVenueList(data['venues'].map((val) => <option value={val}>{val}</option>));
        }
        getVenues();
      }, [user_id]);

    useEffect(() => {
        setInterval(calculateInterval(startDate, endDate));
    }, [startDate, endDate]);

    useEffect(() => {
        if (timeRange === '1D') {
            setEndDate(new Date(startDate.getTime() + ONE_DAY_FUTURE))
        } 
        
        else if (timeRange === '3D') {
            setEndDate(new Date(startDate.getTime() + 3 * ONE_DAY_FUTURE))
        } 
        
        else if (timeRange === '1W') {
            setEndDate(new Date(startDate.getTime() + ONE_WEEK_FUTURE))
        } 
        
        else if (timeRange === '1M') {
            setEndDate(new Date(startDate.getTime() + 30 * ONE_DAY_FUTURE))
        }
    }, [startDate, timeRange]);

    useEffect(() => {
        if (!data || data.length === 0) {
          setInsights(null);
          return;
        }
        const maxTemp = Math.max(...data.map(d => d.temperatureMax));
        const minTemp = Math.min(...data.map(d => d.temperatureMin));
        const avgTemp = data.reduce((sum, d) => sum + d.temperature, 0) / data.length;
        const totalPrecip = data.reduce((sum, d) => sum + d.precipitation, 0);
        const precipOccurrences = data.filter(d => d.precipitation > 0).length;
        const probabilityOfRain = (precipOccurrences / data.length) * 100;
        const maxWindSpeed = Math.max(...data.map(d => d.windSpeedMax));
        const avgWindSpeed = data.reduce((sum, d) => sum + d.windSpeed, 0) / data.length;
        const avgWindDirection = data.reduce((sum, d) => sum + d.windDirection, 0) / data.length;
        const avgCloudCover = data.reduce((sum, d) => sum + d.cloud, 0) / data.length;
        setInsights({
          maxTemp,
          minTemp,
          avgTemp,
          totalPrecip,
          probabilityOfRain,
          maxWindSpeed,
          avgWindSpeed,
          avgWindDirection,
          avgCloudCover,
        });
      }, [data]);

    const handleStartDateChange = (date) => {
        setStartDate(new Date(date));
    };

    const handleEndDateChange = (date) => {
        if (date && date.getTime() === endDate?.getTime()) {
            setEndDate(null);
        } 
        
        else if (date - startDate <= 0) {
            // end date one day after start date
            setEndDate(new Date(startDate.getTime() + 24 * 60 * 60 * 1000));
        } 
        
        else {
            setEndDate(new Date(date));
        }
    };

    const handleTimeRangeChange = (range) => setTimeRange(range);

    const DefaultIcon = L.icon({ iconUrl: icon, shadowUrl: iconShadow, });
    L.Marker.prototype.options.icon = DefaultIcon;

    return (
        <div className="expanded-tracker-page">
            <SideMenu userId={user_id} />
            <div className="main-content">
                <div className="dashboard-container">
                <h1>Weather Forecasts</h1>

                <DateFilter
                    startDate={startDate}
                    endDate={endDate}
                    timeRange={timeRange}
                    onStartDateChange={handleStartDateChange}
                    onEndDateChange={handleEndDateChange}
                    onTimeRangeChange={handleTimeRangeChange}
                    selectedVenue={selectedVenue}
                />

                <TabSelector
                    activeTab={activeTab}
                    onTabChange={(tabId) => setActiveTab(tabId)}
                />

                {/* weather charts */}
                <div className="graph-container">
                    {activeTab === 'temperature' && (
                    <WeatherChart
                        data={data}
                        startDate={startDate}
                        endDate={endDate}
                        interval={interval}
                        unitSettings={unitSettings}
                        calculateDomain={calculateDomain}
                        chartConfig={CHART_CONFIGS.temperature}
                        CustomTooltip={CustomTooltip}
                    />
                    )}
                    {activeTab === 'precipitation' && (
                    <WeatherChart
                        data={data}
                        startDate={startDate}
                        endDate={endDate}
                        interval={interval}
                        unitSettings={unitSettings}
                        calculateDomain={calculateDomain}
                        chartConfig={CHART_CONFIGS.precipitation}
                        CustomTooltip={CustomTooltip}
                    />
                    )}
                    {activeTab === 'allSky' && (
                    <WeatherChart
                        data={data}
                        startDate={startDate}
                        endDate={endDate}
                        interval={interval}
                        unitSettings={unitSettings}
                        calculateDomain={calculateDomain}
                        chartConfig={CHART_CONFIGS.allSky}
                        CustomTooltip={CustomTooltip}
                    />
                    )}
                    {activeTab === 'cloud' && (
                    <WeatherChart
                        data={data}
                        startDate={startDate}
                        endDate={endDate}
                        interval={interval}
                        unitSettings={unitSettings}
                        calculateDomain={calculateDomain}
                        chartConfig={CHART_CONFIGS.cloud}
                        CustomTooltip={CustomTooltip}
                    />
                    )}
                    {activeTab === 'rh2m' && (
                    <WeatherChart
                        data={data}
                        startDate={startDate}
                        endDate={endDate}
                        interval={interval}
                        unitSettings={unitSettings}
                        calculateDomain={calculateDomain}
                        chartConfig={CHART_CONFIGS.rh2m}
                        CustomTooltip={CustomTooltip}
                    />
                    )}
                    {activeTab === 'windSpeed' && (
                    <WeatherChart
                        data={data}
                        startDate={startDate}
                        endDate={endDate}
                        interval={interval}
                        unitSettings={unitSettings}
                        calculateDomain={calculateDomain}
                        chartConfig={CHART_CONFIGS.windSpeed}
                        CustomTooltip={CustomTooltip}
                    />
                    )}
                    {activeTab === 'temperatureMin' && (
                    <WeatherChart
                        data={data}
                        startDate={startDate}
                        endDate={endDate}
                        interval={interval}
                        unitSettings={unitSettings}
                        calculateDomain={calculateDomain}
                        chartConfig={CHART_CONFIGS.temperatureMin}
                        CustomTooltip={CustomTooltip}
                    />
                    )}
                    {activeTab === 'temperatureMax' && (
                    <WeatherChart
                        data={data}
                        startDate={startDate}
                        endDate={endDate}
                        interval={interval}
                        unitSettings={unitSettings}
                        calculateDomain={calculateDomain}
                        chartConfig={CHART_CONFIGS.temperatureMax}
                        CustomTooltip={CustomTooltip}
                    />
                    )}
                </div>

                {/* Time range buttons */}
                <div className="time-range-container">
                    <button
                    className={`time-range-button ${timeRange === '1D' ? 'selected' : ''}`}
                    onClick={() => setTimeRange('1D')}
                    >
                    1D
                    </button>
                    <button
                    className={`time-range-button ${timeRange === '3D' ? 'selected' : ''}`}
                    onClick={() => setTimeRange('3D')}
                    >
                    3D
                    </button>
                    <button
                    className={`time-range-button ${timeRange === '1W' ? 'selected' : ''}`}
                    onClick={() => setTimeRange('1W')}
                    >
                    1W
                    </button>
                    <button
                    className={`time-range-button ${timeRange === '1M' ? 'selected' : ''}`}
                    onClick={() => setTimeRange('1M')}
                    >
                    1M
                    </button>
                </div>

                {/* Alerts */}
                <div className="alerts">
                    {alerts.map((alert, index) => (
                    <div key={index} className="alert">
                        <span>{alert.type}: {alert.value}</span>
                        <p>{alert.message}</p>
                    </div>
                    ))}
                </div>
                </div>

                {/* Insights on the right */}
                <div className="insights-container">
                <h1>Weather Insights</h1>
                <div className="insights-tab-data">
                    {insights ? (
                    <div className="insights-flex-container">
                        <div className="insight-item">
                        <img src={IconTempLite} alt="Temperature Icon" className="insight-icon"/>
                        <p className="insight-value">{insights.maxTemp.toFixed(2)}°{unitSettings[TEMP_UNIT_KEY]}</p>
                        <p className="insight-label">Max Temperature</p>
                        </div>

                        <div className="insight-item">
                        <img src={IconRainLite} alt="Rain Icon" className="insight-icon"/>
                        <p className="insight-value">{insights.totalPrecip.toFixed(2)} {unitSettings[PRECIP_UNIT_KEY]}</p>
                        <p className="insight-label">Total Precipitation</p>
                        <p className="insight-value">{insights.probabilityOfRain.toFixed(0)}%</p>
                        <p className="insight-label">Probability of Rain</p>
                        </div>

                        <div className="insight-item">
                        <img src={IconWindLite} alt="Wind Icon" className="insight-icon" />
                        <p className="insight-value">{insights.maxWindSpeed.toFixed(2)} {unitSettings[WIND_UNIT_KEY]}</p>
                        <p className="insight-label">Max Wind Speed</p>
                        <p className="insight-value">{getWindDirection(insights.avgWindDirection)}</p>
                        <p className="insight-label">Avg Wind Direction</p>
                        </div>

                        <div className="insight-item">
                        <img src={IconCloudLite} alt="Cloud Cover Icon" className="insight-icon" />
                        <p className="insight-value">{(insights.avgCloudCover).toFixed(0)}%</p>
                        <p className="insight-label">Avg Cloud Cover</p>
                        </div>
                    </div>
                    ) : (
                    <p>No data available for insights.</p>
                    )}
                </div>
                </div>
            </div>
        </div>
    );
};

export default ExpandedTracker;